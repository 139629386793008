import React from "react"
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/functions';
import "firebase/storage";
import { FirebaseConfig } from "../config/keys";
//import { fixAccounts } from '../utils/Utils';
//firebase.initializeApp(FirebaseConfig);


//export const bookingRef = databaseRef.child("bookings");
export default class fixAccountsWithMobile extends React.Component {

  
    async componentDidMount() {

    }

    dropInForm =() => {
        return ( 
            <div>
                <button onClick={()=>{this.fixAccounts()}}>
                    Fix Accounts 
                </button>
            </div>
        )
    }

    render() {
  
          return (
             this.dropInForm()
          );
    }

    fixAccounts = () => {
        //alert("test");
        console.log("Test Start")
        console.log("firebase",firebase);
        try{
            firebase.database().ref("accounts/").once('value', function(accounts) {
                accounts.forEach(function (userAccount) {
                //console.log("account",userAccount.val());
               // console.log("Path", "accounts/" + userAccount.val().accountId + "/users/");
               if (typeof userAccount.val().mobile === 'undefined' || userAccount.val().mobile == null || userAccount.val().mobile === '' )
                firebase.database().ref("accounts/" + userAccount.val().accountId + "/users/").orderByChild("createdAt").once('value', function(firstUser) {
                    firstUser.forEach(function (user) {
                    if( user.val().mobile)
                    {
                        console.log("User phone ",user.val().mobile);
                        console.log("Update accounts/" + userAccount.val().accountId + '/mobile/'  );
                        let userUpdate = {};
                        userUpdate["accounts/" + userAccount.val().accountId + "/mobile"] = user.val().mobile;
                        firebase.database().ref().update(userUpdate);
                    }
                    })
                });
            })
        });
        }
        catch (ex)
        {
            console.log("Test Error", ex.message);
        }
        //alert("test end");
        console.log("Test End");
     }

   

}

