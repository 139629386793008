import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./reducers/store";
import ProtectedRoute from './components/ProtectedRoute';
import Login from './views/Login';
import ResetPassword from './views/resetPassword';
import Dashboard from './views/Dashboard';
import TruckTypes from './views/TruckTypes';
import Bookings from './views/Bookings';
import Promos from './views/Promos';
import Users from './views/Users';
import Drivers from './views/Drivers';
import Accounts from './views/Accounts';
import Referral from './views/Referral';
import { fetchUser}  from "./actions/authactions";
import AuthLoading from './components/AuthLoading';
import Notifications from './views/Notifications';
import AdminNotifications from './views/AdminNotifications';
import SystemAdminNotifications from './views/SystemAdminNotifications';
import Lots from './views/Lots';
import Contracts from './views/Contracts';
import Payment from './views/Payment';
import CodeFix from './utils/CodeFix';
// import WebNotification from './components/WebNotification';
function App() {
  store.dispatch(fetchUser());
  return (
    <Provider store={store}>
      <AuthLoading>
        {/* <WebNotification /> */}
        <Router>
          <Switch>
            <ProtectedRoute exact component={Dashboard} path="/" permit={"admin,fleetadmin"}/>
            <ProtectedRoute exact component={TruckTypes} path="/trucktypes" permit={"admin"}/>
            <ProtectedRoute exact component={Accounts} path="/accounts" permit={"admin,fleetadmin"}/>
            <ProtectedRoute exact component={Bookings} path="/bookings" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={Lots} path="/lots" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={Contracts} path="/contracts" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={Promos} path="/promos" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={Users} path="/drivers" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={Drivers} path="/driversview" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={Referral} path="/referral" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={Notifications} path="/notifications" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={AdminNotifications} path="/AdminNotifications" permit={"admin, fleetadmin"}/>
            <ProtectedRoute exact component={SystemAdminNotifications} path="/SystemAdminNotifications" permit={"admin, fleetadmin"}/>
            <Route exact component={Payment} path="/payment"/>
            {/*<Route exact component={CodeFix} path="/codefix"/>*/}
            <Route component={Login} path="/login"/>
            <Route exact component={ResetPassword} path="/resetPassword"/>
          </Switch>
        </Router>
      </AuthLoading>
    </Provider>
  );
}

export default App;
