export const FirebaseConfig = {
  apiKey: "AIzaSyAQcM3YnPFs0pm_RMIm94IdDcHyBd2EDzc",
  googleMapsApiKey: "AIzaSyAQcM3YnPFs0pm_RMIm94IdDcHyBd2EDzc",
  authDomain: "gograin-prod.firebaseapp.com",
  databaseURL: "https://gograin-prod-default-rtdb.firebaseio.com",
  projectId: "gograin-prod",
  storageBucket: "gograin-prod.appspot.com",
  messagingSenderId: "645321835929",
  appId: "1:645321835929:web:3f204397d8a01de059ff08",
  measurementId: "G-KS6WHJ0GKT",
  braintreeToken: "production_bnv3ggsd_xjh6xw4wkdxtf58z"
  };