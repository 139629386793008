export default {
    booking_date:'Booking Date',
    trip_start_time:'Trip Start Time',
    trip_end_time:'Trip End Time',
    customer_name:'Customer Name',
    truck_type:'Truck Type',
    vehicle_no:'Vehicle Number',
    pickup_address:'Pickup Address',
    drop_address:'Drop address',
    assign_driver:'Assign Driver',
    booking_status:'Booking Status',
    trip_cost:'Trip cost',
    discount_amount:'Discount Amount',
    payment_status:'Payment Status',
    payment_mode:'Payment Mode',
    booking_text:'En-Route Bookings',
    image:'Image',
    name:'Name',
    rate_per_km:'Rate Per Kilometer',
    rate_per_hour:'Rate Per Hour',
    convenience_fee:'Convenience Fees',
    today_text:'Today',
    gross_earning:'Gross Earnings',
    admin_convenience_fee:'Admin Convenience Fees',
    this_month_text:'This Month',
    total:'Total',
    real_time_driver_section_text:'Drivers Realtime',
    allow_location:'Allow Location for the Realtime Map',
    sign_in:'Sign in',
    ResetPassword: 'Reset Password',
    email_address:'Email Address',
    password:'password',
    sign_in_error:'Sign In Error. Please check Email and Password.',
    device_type:'Device Type',
    user_type:'User Type',
    title:'Title',
    body:'Body',
    push_notification_title:'Push Notifications',
    admin_notification_title:'Admin Notifications',
    system_admin_notification_title:'System Admin Notify',
    promo_name:'Promo Name',
    description:'Description',
    promo_type:'Promo Type',
    promo_discount_value:'Promo Discount value',
    max_limit:'Max Limit Value',
    min_limit:'Minimum Order Count',
    start_date:'Start Date (dd/mm/yyyy)',
    end_date:'End Date (dd/mm/yyyy)',
    promo_usage:'Promo Usage Limit',
    promo_used_by:'Promo Used By',
    promo_offer:'Promo and Offers',
    referral_bonus:'Referral Bonus',
    submit:'Submit',
    update_failed:'Update Failed',
    first_name:'First Name',
    last_name:'Last Name',
    email:'Email',
    mobile:'Mobile',
    profile_image:'Profile Image',
    vehicle_model:'Vehicle Model',
    account_approve:'Account Approved',
    driver_active:'Driver Active Status',
    approve_bidding:'Approve Bidding',
    license_image:'License Image',
    vehicle_number:'Vehicle Number',
    wallet_balance:'Wallet Balance',
    signup_via_referral:'Signup Via Referral',
    referral_id:'Referral Id',
    all_user:'All Users',
    notification_sent:' notifications sent.',
    notification_sent_failed:'Notification sending failed.',
    no_user_match:'No Users Matched',
    no_user_matching:'No users found matching your selection.',
    dashboard_text:'Dashboard',
    user:'Users',
    booking_history:'Booking History',
    promo:'Promos',
    logout:'Log Out',
    lot_history: 'Booking Requests',
    lot_text: 'Booking Requests',
    grain_type: 'Grain Type',
    loads: 'Loads',
    price: 'Price',
    lot_end_date: 'End Date',
    lot_end_time: 'End Time',
    lot_end_location: 'End Location',
    lot_start_date: 'Start Date',
    lot_start_location: 'Start Location',
    lot_start_time : 'Start Time',
    lot_status : 'Status',
    lot_status_reason : 'Lot Close Reason',
    tons : 'Tons',
    bid_date: 'Bid Date',
    bid_price: 'Bid Price',
    bid_time: 'Bid Time',
    bidder: 'Bidder',
    bid_rating: 'Bid Rating',
    bid_end_time: 'Bid End Time',
    bid_end_date: 'Bid End Date',
    bid_load_count: 'Load Count',
    bid_oetype: 'OE Type',
    bid_start_time: 'Bid Start Time',
    bid_start_date: 'Bid Start Date',
    bid_status_reason: 'Bid Status Reason',
    bid_optional_extractor: 'Bid Optional Extractor',
    bid_truck_type: 'Truck Type',
    bid_start_date_time: 'Bid Date & Time',
    bid_text: 'Bidder',
    contracts: 'Contracts',
    user_created_date: 'Created',
    contract_create_date: 'Created Date',
    contract_due_date: 'Due Date',
    contract_lat_start: 'Start Latitude',
    contract_lon_start: 'Start Longitude',
    contract_lat_end: 'End Latitude',
    contract_lon_end: ' End Longitude',
    contract_end_location: 'End Location',
    contract_must_by_end: 'Must End By',
    contract_must_by_start: 'Must Start By',
    contract_percent: 'Percent Complete',
    contract_price: 'Price',
    contract_status: 'Status',
    contract_start_date: 'Start Date',
    contract_start_location: 'Start Location',
    contract_total_ton: 'Total Tons',
    contract_truck_pref: 'Truck Preference',
    contract_total_trip: 'Total Trips',
    truckType_axles: 'Axles',
    truckType_status: 'Status',
    truckType_loadingFee: 'Loading Fee',
    truckType_unloadingFee: 'Unloading Fee',
    truckType_primaryRateTonnage: 'Primary Rate Tonnage',
    truckType_secondaryRateTonnage: 'Secondary Rate Tonnage',
    truckType_index: 'Order By Index',
    TBD: 'TBD',
    ResetPassword: 'Reset Password',
    NewPassword: 'New Password',
    PasswordResetEmail: 'If the account exists, please check email for password reset instructions.',
    Submit: 'Submit',
}