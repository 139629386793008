import * as moment from 'moment';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GridOnIcon from '@material-ui/icons/GridOn';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ImageIcon from '@material-ui/icons/Image';

function generateRandId() {

    // create five IDs of eight base 36 characters
    var ids = [];
    //for (var i=0; i<5; i++) 
        //ids[i] = GetBase36(8);
        return GetBase36(8);
    //return ids;
}
 function GetBase36 (length)
{
    var _base36chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var base36length = _base36chars.length;
    var sb = '';
    for (var i=0; i<length; i++) 
        sb += _base36chars.charAt(Math.random()*(base36length));
    return sb;
   
}

export const randIdAccount = () =>   {

    var randId = generateRandId();
    var Id = "ACCN-" + moment().format('YYYYMM-')  + randId;
    return Id;
}

export const generatePassword= () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const randIdDocument = () =>   {

    var randId = generateRandId();
    var Id = "DOCC-" + moment().format('YYYYMM-')  + randId;
    return Id;
}


export const mimeIconLabel = (mimetype) =>
{
   switch (mimetype)
   {
      //Excel Files 
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      
      //Word Files
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
         return "AttachFileIcon";

      //PDF Files
      case "application/pdf": 
         return "PictureAsPdfIcon";

      //Powerpoint Files
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation ":
         return "SlideshowIcon";

      


      //Image Files 
      case "image/gif":
      case "image/jpeg":
      case "image/png":
      case "image/svg+xml":
      case "image/webp":
         return "ImageIcon";
      //Text Files
      case "text/plain":
         return "AttachFileIcon";

      default:
         return "AttachFileIcon";
   }
}

export const mimeIconMarkup = (mimetype) =>
{
   switch (mimetype)
   {
      //Excel Files 
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return (<GridOnIcon/>);
      
      //Word Files
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (<AttachFileIcon/>);

      //PDF Files
      case "application/pdf": 
         return (<PictureAsPdfIcon/>);

      //Powerpoint Files
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation ":
         return (<SlideshowIcon/>);
      
      //Image Files 
      case "image/gif":
      case "image/jpeg":
      case "image/png":
      case "image/svg+xml":
      case "image/webp":
         return (<ImageIcon/>);
      //Text Files
      case "text/plain":
         return (<AttachFileIcon/>);

      default:
         return (<AttachFileIcon/>);
   }
}

export const fixAccounts = (databaseRef) => {
   alert("test");
   console.log("Test Start")
   console.log("databaseref",databaseRef);
   try{
       databaseRef.ref("accounts/").once('value', accounts =>{
       var account = accounts.val();
       console.log("Account",account.val());

       databaseRef.ref("accounts/" + account.accountId).once('value', firstUser => {
           var user = firstUser.val();
           if (user.mobile)
           databaseRef.ref("accounts/" + account.accountId).child('mobile').update(user.mobile);
       });
   });
   }
   catch (ex)
   {
       console.log("Test Error", ex.message);
   }
   alert("test end");
   console.log("Test End");
}