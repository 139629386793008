import React,{ useState,useEffect, forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import {
  editTruckType
  }  from "../actions/trucktypeactions";

export default function TruckTypes() {
  const columns =  [
    { title: languageJson.image,field: 'image',render: rowData => <img alt='Truck' src={rowData.image} style={{width: 50}}/>},
    { title: languageJson.name, field: 'name' },
    { title: languageJson.truckType_axles, field: 'axles', type: 'numeric' },
    { title: languageJson.truckType_loadingFee,field: 'loadingFee', type: 'currency'},
    { title: languageJson.truckType_unloadingFee,field: 'unloadingFee', type: 'currency'},
    { title: languageJson.truckType_primaryRateTonnage,field: 'primaryRateTonnage', type: 'numeric'},
    { title: languageJson.truckType_secondaryRateTonnage,field: 'secondaryRateTonnage', type: 'numeric'},
    { title: languageJson.truckType_status,field: 'status'},
    { title: languageJson.truckType_index,field: 'index',type: 'numeric'}

    ];
  const [data, setData] = useState([]);
  const truckTypes = useSelector(state => state.trucktypes);
  const auth = useSelector( state => state.auth.info.profile);
  const dispatch = useDispatch();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };



  useEffect(()=>{
        if(truckTypes.trucks){
            setData(truckTypes.trucks);
        }
  },[truckTypes.trucks]);

  console.log("truckTypes",truckTypes);
  const removeExtraKeys = (tblData) =>{
    for(let i = 0;i<tblData.length;i++){
      if(tblData[i].loadingFee) tblData[i].loadingFee = parseFloat(tblData[i].loadingFee);
      if(tblData[i].unloadingFee) tblData[i].unloadingFee = parseFloat(tblData[i].unloadingFee);
      if(tblData[i].primaryRateTonnage) tblData[i].primaryRateTonnage = parseFloat(tblData[i].primaryRateTonnage);
      if(tblData[i].secondaryRateTonnage) tblData[i].secondaryRateTonnage = parseFloat(tblData[i].secondaryRateTonnage);
    }
    return tblData;
  }

  if (auth.isAdmin)
  {
  return (
    truckTypes.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.truck_type}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={{
        exportButton: true
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.push(newData);
              dispatch(editTruckType(removeExtraKeys(tblData),"Add"));
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
              dispatch(editTruckType(removeExtraKeys(tblData),"Update"));
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.splice(tblData.indexOf(oldData), 1);
              dispatch(editTruckType(removeExtraKeys(tblData),"Delete"));
            }, 600);
          }), 
      }}
    />
  );
    }
    else
    {
      return (
        <div>
          This Page is not accessible.
        </div>

      )
      ;
    }
}
