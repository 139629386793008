import { driverListRef } from "../config/firebase";
import { 
    FETCH_DRIVERS,
    FETCH_DRIVERS_SUCCESS,
    FETCH_DRIVERS_FAILED,
  //  EDIT_DRIVERS
} from "./types";

export const fetchDrivers = (bookingAccountId) => dispatch => {
    dispatch({
      type: FETCH_DRIVERS,
      payload: null
    });


    driverListRef(bookingAccountId).on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].users = i
          return data[i]
        });
        dispatch({
          type: FETCH_DRIVERS_SUCCESS,
          payload: arr
          //payload: snapshot.val()
        });
      } else {
        dispatch({
          type: FETCH_DRIVERS_FAILED,
          payload: "No drivers available."
        });
      }
    });
  };




/*  export const editTruckType = (truckTypes,method) => dispatch =>{
    dispatch({
      type: EDIT_DRIVERS,
      payload: method
    });
    driverListRef.set(truckTypes);
  }*/