import React,{ useState,useEffect,forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import  languageJson  from "../config/language";
import FileUploadDocuments from '../components/FileUpload';
import {
     editUser, deleteUser
  }  from "../actions/usersactions";
//import UserDetails from '../components/UserDetails';

export default function Users() {
  const [data, setData] = useState([]);
  const [trucks, setTrucks] = useState({});
  const auth = useSelector( state => state.auth.info.profile);
 
  const usersdata = useSelector(state => auth.isAdmin ? state.usersdata : state.usersdata?.users?.filter(d => d.accountId === auth.accountId) );
  const truckTypes = useSelector(state => state.trucktypes);
  const dispatch = useDispatch();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


  useEffect(()=>{
    if(usersdata.users){
        setData(usersdata.users);
    }else {
      setData(usersdata);
    }
  },[usersdata?.users]);

  useEffect(()=>{
    if(truckTypes.trucks){
        let obj =  {};
        truckTypes.trucks.map((truck)=> obj[truck.name]=truck.name)
        setTrucks(obj);
    }
  },[truckTypes.trucks]);

  const columns = [
      { title: languageJson.first_name, field: 'firstName', editable:'never',filtering: true},
      { title: languageJson.last_name, field: 'lastName', editable:'never',filtering: true},
      { title: languageJson.user_type, field: 'usertype', lookup: { rider: 'Customer', driver: 'Driver', admin: 'Admin' }, editable:'never',filtering: true},
      { title: languageJson.email, field: 'email', editable:'never',filtering: true},
      { title: languageJson.mobile, field: 'mobile', editable:'never',filtering: true},
      { title: languageJson.profile_image, field: 'profile_image',render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never',filtering: false},
      
      { title: languageJson.account_approve, field: 'approved', type:'boolean',filtering: true},
      { title: languageJson.driver_active, field: 'driverActiveStatus', type:'boolean',filtering: true},
      { title:languageJson.licence_image, field: 'licenseImage',render: rowData => rowData.licenseImage?<img alt='License' src={rowData.licenseImage} style={{width: 100}}/>:null, editable:'never'},
      { title:languageJson.approve_bidding, field: 'approvedForBidding', type:'boolean',filtering: true},
      { title:languageJson.user_created_date, field: 'createdAt', type: 'datetime', editable:'never',filtering: true}
  ];

  return (
    usersdata.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.all_user}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={{
        filtering: true,
        exportButton: true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10,20,50]

      }}
      detailPanel = { rowData => {
          return (
           <FileUploadDocuments />
          )
      }
      }
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editUser(oldData.id,newData));
            }, 600);
          }),  
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(deleteUser(oldData.id));
            }, 600);
          }),   
      }}
    />
  );
}
