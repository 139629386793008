
export const validate = (values) => {
    console.log(values);
   
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Cannot be blank";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }
    if (!values.firstName) {
      errors.firstName = "Cannot be blank";
    } 
    if (!values.lastName) {
      errors.lastName = "Cannot be blank";
    } 
    if (!values.address) {
        errors.address = "Cannot be blank";
      } 
    if (values.mobile.length > 9) {
      errors.password = "Telephone number should be +1 (XXX) XXX-XXXX";
    }
    return errors;
  }
  
  export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  export const validateEmail = (email) =>
{
  var emailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

 if (email.match(emailformat))
  {
    return (true)
  }
    alert("You have entered an invalid email address!")
    return (false)
}