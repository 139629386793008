import React,{ useState,useEffect, useRef,forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularLoading from "./CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import  languageJson  from "../config/language";
import { editLoad } from '../actions/loadactions';
import AutoCompleteModal from '../components/AutoCompleteModal';

export default function MembersDetails(rowData) {

  const truckType = useSelector(state => state.trucktypes.trucks);
  const auth = useSelector( state => state.auth.info.profile); 
  let trucks = truckType.map(d => d.name );
  console.log(trucks)
  const driversList = useSelector(state=> state.accountsdata?.accounts);
  let drivers = driversList.filter( item  => item.accountId === rowData.row.bidderAccountId)[0]['users'];   
  const driversData = Object.keys(drivers).map( item => ({key: item, name: `${drivers[item].firstName} ${drivers[item].lastName}`}));
  var driversLookup = driversData.reduce(function(acc,cur,i){ acc[cur.key] = cur.name; return acc;},{});
  var statusLookupAdmin = { Created: "Created", Cancelled: "Cancelled", OnHold: "On Hold", Activated: "Activated", PaymentPending: "Payment Pending", Unscheduled: "Unscheduled", Scheduled: "Scheduled", Delivered: "Delivered", Rejected: "Rejected", Closed: "Closed", Completed: "Completed" };
  //var statusLookupAccountAdmin = { Created: "Created", Cancelled: "Cancelled", OnHold: "On Hold", Unscheduled: "Unscheduled", Scheduled: "Scheduled", Rejected: "Rejected" };
  var statusLookup = {};
 // if (auth.isAdmin)
 // {
    statusLookup = statusLookupAdmin;
 // }
  //if (!auth.isAdmin)
 // {
 //   statusLookup = statusLookupAccountAdmin;
 // }

    const columns =  [
        { title: "Load Id", field: 'loadId', hidden: true},
        { title: "Load #", field: 'loadCountNumber' , editable: 'never'},
        { title: "ASSIGNED DRIVER *(REQ)", field:'driver', lookup:  driversLookup, validate: rowData=> rowData.driver ===''? {isValid: false, helperText: 'Driver must be selected'}: true},
        { title: "SCHEDULED START *(REQ)", field: 'suggestedStart', type: 'datetime'},
        { title: "STATUS *(REQ)", field: 'status', lookup: { Created: "Created", Cancelled: "Cancelled", OnHold: "On Hold", Activated: "Activated", PaymentPending: "Payment Pending", Unscheduled: "Unscheduled", Scheduled: "Scheduled", Delivered: "Delivered", Rejected: "Rejected", Closed: "Closed", Completed: "Completed" }},
        { title: "Notes", field: 'notes', validate: rowData => rowData.notes?.length >= 376 ? 'Notes cannot be more than 375 characters': ''},
        { title: "GrainType", field: 'grainType'},
        { title: "Tonnes", field: 'tons'},
        { title: languageJson.bid_truck_type, field: 'truckType', lookup:  trucks.reduce((a, v) => ({ ...a, [v]: v}), {})  },
        { title: 'Rate Class ', field: 'rateClass' , lookup: {"Primary Rate": "Primary Rate", "Secondary Rate": "Secondary Rate" }},
        { title: "Trip Price", field: 'price', type: 'currency'},
        { title: "Start Location", field: 'startLocation'},
        { title: "Start Latitude", field: 'startLatitude'},
        { title: "Start Longitude", field: 'startLongitude'},
        { title: "End Location", field: 'endLocation'},
        { title: "End Latitude", field: 'endLatitude'},
        { title: "End Longitude", field: 'endLongitude'},
        { title: "Load Payment Status", field: 'loadPaymentStatus', lookup: { UnPaid: 'Un-Paid', Paid: 'Paid'}},
        { title: "Booking Id", field: 'bookingId', editable: 'never'},
        { title: "Contract Id", field: 'contractId', hidden: true},
        { title: "Shipper Account", field: 'accountId', hidden: true },
        { title: "Driver Account", field: 'bidderAccountId', hidden: true},
        { title: "Created On", field: 'CreatedOn', type:'datetime', editable:'never'},
        { title: "Modified On", field: 'ModifiedOn', type:'datetime', editable:'never'},
    ];
    const [value, setValue ] = useState('');
    const [data, setData] = useState([]);
    const loadsdata = useSelector(state => state.contractsdata);
    const dispatch = useDispatch();

    const unmounted = React.useRef(false);

    const onChange = (event, {newValue}) => {
        setValue(newValue);
    }

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    //const validateDriverSelected = (value) => {
    //}
    useEffect(()=>{
        let loads = [];
        if(rowData.row.loads){
            for(let key in rowData.row.loads){
                // rowData.row.loads[key].cid = rowData.row.id;
                loads.push(rowData.row.loads[key]);

            }
            if(!unmounted.current)
            setData(loads);
        }
        return () => {
           unmounted.current = true;
        }
  },[rowData.row.loads, rowData.row.id]);
    return (
        data===null && loadsdata.loading? <CircularLoading/>:
        <MaterialTable
            title={languageJson.loads}
            columns={columns}
            icons={tableIcons}
            data={data}
            options={{
            filtering: true,
            exportButton: true,
            exportAllData: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "left",
            pageSize: 10,
            pageSizeOptions: [10,20,50]
            }}
            editable={{
        // onRowAdd: newData =>
        //   new Promise(resolve => {
        //     setTimeout(() => {
        //       resolve();
        //       const tblData = data;
        //       tblData.push(newData);
        //       dispatch(editLoad(newData, "Update"));
        //       // dispatch(editNotifications(newData,"Add"));
        //     }, 600);
        //   }),

          onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
              dispatch(editLoad(newData,"Update"));
            }, 600);
          }),
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
            //   dispatch(editNotifications(newData,"Delete"));
            }, 600);
          }),
      }}
    />
  );
}