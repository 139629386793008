import React,{ useState,useEffect, forwardRef  } from 'react';
import MaterialTable, {MTableToolbar} from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularLoading from "../components/CircularLoading";
import { useSelector } from "react-redux";
import  languageJson  from "../config/language";
//import FileUploadDocuments from '../components/FileUpload';
import RecordDetails from '../components/DocumentDetails';
export default function Bookings() {

  const truckType = useSelector(state => state.trucktypes.trucks);
    let trucks = truckType.map(d =>    d.name );

    const columns =  [
      
      { title:  'Booking Id', field: 'bookingId', editable: 'never' ,filtering: true },
      { title: 'Created', field: 'createdDate', type: 'date',filtering: true },
      { title: languageJson.customer_name,field: 'customer_name',filtering: true},
      { title: 'Customer Account Id',field: 'customer_AccountId',filtering: true},
      { title: 'Customer User Id',field: 'customer',filtering: true},
      { title: languageJson.booking_date, field: 'tripdate', type:'datetime',filtering: true },
      { title: languageJson.trip_start_time, field: 'trip_start_time', type:'datetime' ,filtering: true, defaultSort: 'desc'},
      { title: languageJson.trip_end_time, field: 'trip_end_time', type: 'time',filtering: true},
      { title: languageJson.pickup_address, field: 'pickupAddress',filtering: true },
      { title: languageJson.drop_address, field: 'dropAddress' ,filtering: true},
      { title: 'Trip Distance', field: 'distance' ,filtering: true},
      { title: languageJson.truck_type, field: 'carType',filtering: true, lookup:  trucks.reduce((a, v) => ({ ...a, [v]: v}), {})  },
      { title: 'Rate Class ', field: 'rateClass' , lookup: {"Primary Rate": "Primary Rate", "Secondary Rate": "Secondary Rate" }},
      { title: 'Driver Id', field: 'driver',filtering: true },
      { title: languageJson.assign_driver, field: 'driver_name' ,filtering: true},
      { title: 'Driver Account Id', field: 'driver_AccountId',filtering: true },  
      { title: languageJson.booking_status, field: 'status' ,filtering: true, lookup: {Accepted: "Accepted",Start: "Start", InProgress: "In-Progress", PaymentPending: "Payment Pending", End: "End",Cancelled: "Cancelled"}},
      { title: 'Contract Amount', field: 'estimate', type: 'currency',filtering: true },  
      { title: languageJson.trip_cost, field: 'trip_cost', type: 'currency',filtering: true },
      //{ title: languageJson.vehicle_no, field: 'vehicle_number' },  
      { title: 'Transaction Amount', field: 'creditCardAmount', type: 'currency',filtering: true },  
      { title: languageJson.payment_status, field: 'payment_status',filtering: true, lookup: {UnPaid: 'Un-Paid', Paid: 'Paid'}},
      { title: 'Access Code', field: 'otp',editable: 'never' ,filtering: false },
      { title: 'Load Id', field: 'loadId',filtering: true},
      { title: 'Trip Time', field: 'total_trip_time', type: 'time',filtering: true},
      { title: 'Transaction Id', field: 'transactionID', editable: 'never',filtering: true  },
      // { title: languageJson.discount_amount, field: 'discount'},
       
        ];

  const [data, setData] = useState([]);
  const auth = useSelector( state => state.auth.info.profile);

  const bookingdata = useSelector(state => auth.isAdmin ? state.bookingdata?.bookings : state.bookingdata?.bookings.filter( d =>  d.customer_AccountId === auth.accountId || d.driver_AccountId === auth.accountId) );
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  useEffect(()=>{
        if((data?.length === 0 && bookingdata?.length > 0) || (data.length < bookingdata?.length))
            setData(bookingdata);
  },[bookingdata, data]);

  return (
    bookingdata?.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.booking_text}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={{
        exportButton: true,
        exportAllData: true,
        filtering:true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10,20,50]

      }}
      detailPanel = { rowData => {
          return (
           <RecordDetails  row={rowData} syncType="Booking" recordId={rowData.bookingId} />
          )
      }
      }

      components={{
        Toolbar: props => (
          <div>
            <MTableToolbar {...props} />
            {/* <div style={{padding: '0px 10px'}}>
             <h1>Filter by active status: </h1>
             <SelectElement />
            </div> */}
          </div>
        ),
      }}
    />
  );
}
