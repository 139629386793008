import React from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  MenuList,
  MenuItem
}from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch,useSelector } from "react-redux";
import logo from '../assets/sidemenu_logo.jpg';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CarIcon from '@material-ui/icons/DirectionsCar';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import BallotIcon from '@material-ui/icons/Ballot';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
//import NotificationsActive from '@material-ui/icons/CircleNotifications';
import  languageJson  from "../config/language";
import {
  signOut
}  from "../actions/authactions";




function AppMenu() {
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  const auth = useSelector( state => state.auth.info.profile);
  

  const dashboard = 
    <MenuItem component={Link} to="/">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <Typography variant="inherit">{languageJson.dashboard_text}</Typography>
    </MenuItem>;

  const accounts = 
      <MenuItem component={Link} to="/accounts">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.TRB || 'Accounts'}</Typography>
        </MenuItem>;

  const drivers = 
      <MenuItem component={Link} to="/driversview">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.user || 'Drivers'}</Typography>
        </MenuItem>;
    

  const truckTypes = 
  <MenuItem component={Link} to="/trucktypes">
          <ListItemIcon>
            <CarIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.truck_type}</Typography>
        </MenuItem>;


  const lotHistory = 
  <MenuItem component={Link} to="/lots">
          <ListItemIcon>
            <BallotIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.lot_history}</Typography>
        </MenuItem>;
  const contracts =
  <MenuItem component={Link} to="/contracts">
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.contracts}</Typography>
        </MenuItem>  ;

  const bookingHistory =
  <MenuItem component={Link} to="/bookings">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.booking_history}</Typography>
        </MenuItem>  ;

  const pushNotification =
  <MenuItem component={Link} to="/notifications">
          <ListItemIcon>
            <NotifyIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.push_notification_title}</Typography>
        </MenuItem>  ;

  const adminNotification =
  <MenuItem component={Link} to="/adminnotifications">
          <ListItemIcon>
            <NotifyIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.admin_notification_title}</Typography>
        </MenuItem>  ;
    
    const systemAdminNotification =
    <MenuItem component={Link} to="/systemadminnotifications">
            <ListItemIcon>
              <NotifyIcon />
            </ListItemIcon>
            <Typography variant="inherit">{languageJson.system_admin_notification_title}</Typography>
          </MenuItem>  ;


    return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center',backgroundColor:'#e1e1e1'}}>
        <img style={{marginTop:'5px',marginBottom:'5px',width:'229px',height:'120px'}} src={logo} alt="Logo" />
      </div>
      <Divider/>
      <MenuList>
        {dashboard}
        {accounts}
        {drivers}
        {auth.isAdmin && truckTypes}
        {lotHistory}
        {contracts}
        {bookingHistory}
        {auth.isAdmin && pushNotification}
        {auth.isAdmin && adminNotification}
        {auth.isAdmin && systemAdminNotification}
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{languageJson.logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;