import { singleDocumentRef, documentsRef } from "../config/firebase";
import moment from 'moment';
import { 
    FETCH_ALL_DOCUMENTS,
    FETCH_ALL_DOCUMENTS_SUCCESS,
    FETCH_ALL_DOCUMENTS_FAILED,
    EDIT_DOCUMENT,
    EDIT_DOCUMENT_SUCCESS,
    EDIT_ACCOUNT_FAILED,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAILED,
    FETCH_ALL_ACCOUNTS_FAILED
} from "./types";
import { randIdDocument } from '../utils/Utils'
export const fetchDocuments = () => dispatch => {
    dispatch({
      type: FETCH_ALL_DOCUMENTS,
      payload: null
    });
    console.log('doc...0', documentsRef);

    documentsRef.on("value", snapshot => {
      console.log('doc...1', snapshot.val())
      if (snapshot.val()) {
        console.log('doc...2', snapshot.val())
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i
          return data[i]
        });
        dispatch({
          type: FETCH_ALL_DOCUMENTS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_ALL_DOCUMENTS_FAILED,
          payload: "No Documents available."
        });
      }
    });
  };
    export const editDocument =  (document) =>  dispatch => {
      dispatch({
        type: EDIT_DOCUMENT,
        payload: document
      });
      const id = randIdDocument();
      //let editedLot = document;
      document.startDate = moment(document.startDate).format('MM/D/YYYY');
      document.expiryDate = moment(document.expiryDate).format('MM/D/YYYY');
      document.birthdate = moment(document.birthdate).format('MM/D/YYYY');
      document.id = id;
         singleDocumentRef(id).set(document).then((res) => {
          console.log('added');
        }).catch((error) => {
          console.log('error', error);

        });
    }
  
    export const deleteDocument = (id) => dispatch =>{
      dispatch({
        type: DELETE_DOCUMENT,
        payload: id
      });
  
      singleDocumentRef(id).remove().then(()=>{
        dispatch({
          type: DELETE_DOCUMENT_SUCCESS,
          payload: null
        });  
      }).catch((error)=>{
        dispatch({
          type: DELETE_DOCUMENT_FAILED,
          payload: error
        });        
      });
    }
