import React from "react"
import axios from 'axios'
import { BraintreeDropIn } from "braintree-web-react"
import { BraintreeHostedFields } from "braintree-web-react"
import * as firebase from 'firebase';
import paymentImage from '../assets/payment.gif'
import { FirebaseConfig } from "../config/keys";
// import './Payment.css';
// //Based on example at: https://codepen.io/NunoMartins21/pen/BVLXgw
// //https://freefrontend.com/css-checkout-forms/

export default class Payment extends React.Component {
  state = {
    clientToken: null,
    msg: null,
    isLoading: null,
  };
 
  instance;
 
  async componentDidMount() {
      // const paymentGatway =  firebase.functions().httpsCallable('paymentGatway'); 
      // const response = await paymentGatway();
      // let clientToken = 'sandbox_ktrfwfgp_5p2rzydxhxpxkmxx';
      let clientToken = FirebaseConfig.braintreeToken;
      // console.log('key',clientToken)
      // let clientToken = response.data.result.clientToken;
      this.setState({clientToken});
      // console.log('Token',response);
      
  }
 
  async purchase() {
    try{
    // Send nonce to your server
    const { nonce } = await this.instance.requestPaymentMethod();
   // Send nonce to your server
   // const { nonce } = await this.instance.tokenize()

    console.log('none...',nonce);
          // const transaction =  firebase.functions().httpsCallable('transaction'); 
          // const response = await transaction({ paymentMethodNonce: nonce, userId: 'zs7dsSHsvKhuHNhlok4woI6ui9g1', 
          // payDetails:{ 
          //   amount: 3.00,  
          //   firstName: 'Sajid Khan',
          //   lastName: 'Naseem',
          //   phonenumber: '+13065809373',
          //   email:'sajid_akhunzada@yahoo.com',
          //   }
          // })
    window.ReactNativeWebView.postMessage(nonce);
    this.setState({isLoading: true});
    // console.log(response)
    }
    catch(err) {
      console.log(err);
    }
  }
 
  dropInField = () => {
   return (
     <>
      <BraintreeDropIn
      options={{ authorization: this.state.clientToken }}
      onInstance={instance => (this.instance = instance)}
    />
    <button  style={{padding:'15px 35px', color: '#fff', borderRadius: 10, backgroundColor:"#243335", width: '100%' }} onClick={this.purchase.bind(this)}>Submit</button>
  </>
   )
 }

 hostedFields = () => {
  return (
     <div className="container">
          <BraintreeHostedFields
            className="drop-in-container"
            options={{
              authorization: this.state.clientToken
            }}
            onInstance={(instance) => (this.instance = instance)}
          >
          <main id="main">
	<section id="left">
		<div id="head">
			<h1>Load Payment</h1>
			<p>Shipment Purchasing</p>
		</div>
		<h3>Total Cost Today:$1340</h3>
	</section>
	<section id="right">
		<h1>Purchase</h1>
            <form id="cardForm">
              <div className="group-form">
              <label className="hosted-fields--label">Card Number</label>
              <div id="card-number" className="hosted-field form-field"></div>
              </div>
              <label className="hosted-fields--label">Expiration Date</label>
              <div id="expiration-date" className="hosted-field"></div>
 
              <label className="hosted-fields--label">CVV</label>
              <div id="cvv" className="hosted-field"></div>
 
              <label className="hosted-fields--label">Postal Code</label>
              <div id="postal-code" className="hosted-field"></div>
            </form>
            </section>
</main>
          </BraintreeHostedFields>
          <button className="submit" onClick={this.purchase.bind(this)}>Submit</button>
        </div>
  )

 }


  render() {
    const center = {
      textAlign: 'center',
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      width: '100%',
    }
    if (!this.state.clientToken) {
      return (
        <div>
          <h1 style={center}>Loading</h1>
        </div>
      );
    }else if(this.state.isLoading){
        return (<div>
        <img style={center} src={paymentImage} alt="Logo" />;
      </div>)
     } else {
      return (
         this.dropInField()
      );
    }
  }



}
