import { userRef, accountRef, singleAccountRef } from "../config/firebase";
import { 
    FETCH_ALL_ACCOUNTS,
    FETCH_ALL_ACCOUNTS_SUCCESS,
    FETCH_ALL_ACCOUNTS_FAILED,
    EDIT_ACCOUNT,
    EDIT_ACCOUNT_SUCCESS,
    EDIT_ACCOUNT_FAILED,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILED,
    ADD_ACCOUNT_SUCCESS,
    ADD_ACCOUNT,
    ADD_ACCOUNT_FAILED
} from "./types";
import {functions} from '../config/firebase';
import {randIdAccount,  generatePassword} from '../utils/Utils';

export const fetchAccounts = () => dispatch => {
    dispatch({
      type: FETCH_ALL_ACCOUNTS,
      payload: null
    });
    accountRef.on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i
          return data[i]
        });
        dispatch({
          type: FETCH_ALL_ACCOUNTS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_ALL_ACCOUNTS_FAILED,
          payload: "No accounts available."
        });
      }
    });
  };

  export const editContract = (id,account) => dispatch =>{
    dispatch({
      type: EDIT_ACCOUNT,
      payload: account
    });
    let editedAccount = account;
    if(account.referralBonus) editedAccount.referralBonus = parseFloat(editedAccount.referralBonus);
    delete editedAccount.id;
    singleAccountRef(id).set(editedAccount).then(()=>{
      dispatch({
        type: EDIT_ACCOUNT_SUCCESS,
        payload: null
      });  
    }).catch((error)=>{
      dispatch({
        type: EDIT_ACCOUNT_FAILED,
        payload: error
      });        
    });
  }

  export const addAccount = (data) => dispatch => {
    dispatch({
      type: ADD_ACCOUNT,
      payload: null
     });
    data.id = randIdAccount();
    data.password = generatePassword();
    var createUser = functions.httpsCallable('createUser');
    createUser(data).then( res => {
       console.log(res);
       dispatch({
        type: ADD_ACCOUNT_SUCCESS,
        payload: null
       });
    }).catch(error => {
      var code = error.code;
      var message = error.message;
      var details = error.details;
      alert(message);
      dispatch({
        type: ADD_ACCOUNT_FAILED,
        payload: error
       });
    });  
  }
 
 export const editAccount = (data) => dispatch => {
  var updateUser = functions.httpsCallable('updateUser');
    updateUser(data).then( res => {
      console.log(res);
      dispatch({
        type: EDIT_ACCOUNT_SUCCESS,
        payload: null
       });
    }).catch( error => {
      var code = error.code;
      var message = error.message;
      var details = error.details;
      alert(message);
      dispatch({
        type: EDIT_ACCOUNT_FAILED,
        payload: error
       });
    });
 }

//  const deleteAccountHelper = async (data) => {
//   let users = data.users;
//   let deletedAccount = await admin.database().ref('accounts/' + data.id).remove();
//   for(let user of users){
//       deleteuserHelper(user.uid);
//   }
// }
// const deleteUserHelper = async (id) => {
//       let deletedUser = await admin.database().ref('users/' + id).remove();

// }


  export const deleteAccount = (data) => dispatch =>{
    dispatch({
      type: DELETE_ACCOUNT,
      payload: data.id
    });
    let deleteUser = functions.httpsCallable('deleteUser');
    deleteUser(data).then((res)=>{
        console.log(res);
        dispatch({
        type: DELETE_ACCOUNT_SUCCESS,
        payload: null
      });  
    }).catch((error)=>{
      dispatch({
        type: DELETE_ACCOUNT_FAILED,
        payload: error
      });        
    });
  
  }

