import { userRef, contractRef, singleContractRef, memberRef, singleMemberRef } from "../config/firebase";
import { 
    FETCH_ALL_CONTRACTS,
    FETCH_ALL_CONTRACTS_SUCCESS,
    FETCH_ALL_CONTRACTS_FAILED,
    EDIT_CONTRACT,
    EDIT_CONTRACT_SUCCESS,
    EDIT_CONTRACT_FAILED,
    DELETE_CONTRACT,
    DELETE_CONTRACT_SUCCESS,
    DELETE_CONTRACT_FAILED,
    EDIT_MEMBER
} from "./types";
import moment from 'moment';
export const fetchContracts = () => dispatch => {
    dispatch({
      type: FETCH_ALL_CONTRACTS,
      payload: null
    });
    contractRef.on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i
          return data[i]
        });
        dispatch({
          type: FETCH_ALL_CONTRACTS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_ALL_CONTRACTS_FAILED,
          payload: "No contracts available."
        });
      }
    });
  };

  export const editContract = (contract, method) => dispatch =>{
    dispatch({
      type: EDIT_CONTRACT,
      payload: contract
    });
    console.log(contract);
    contract.createdDate = moment(contract.createdDate).format('MM/D/YYYY');
    contract.startDate = moment(contract.startDate).format('MM/D/YYYY');
    contract.dueDate = moment(contract.dueDate).format('MM/D/YYYY');
      if(method === 'Add' ){
        singleContractRef.push(contract);
      }else if(method === 'Delete'){
        singleContractRef(contract.id).remove();
      }else{
        singleContractRef(contract.id).set(contract);
      }
  }

  export const deleteContract = (id) => dispatch =>{
    dispatch({
      type: DELETE_CONTRACT,
      payload: id
    });

    singleContractRef(id).remove().then(()=>{
      dispatch({
        type: DELETE_CONTRACT_SUCCESS,
        payload: null
      });  
    }).catch((error)=>{
      dispatch({
        type: DELETE_CONTRACT_FAILED,
        payload: error
      });        
    });

  }

  export const editMemberStatus = (member, method) => dispatch => {
    dispatch({
      type: EDIT_MEMBER,
      payload: member
    });
    console.log(member);
    if(method === 'Add' ){
      singleMemberRef(member.id, member.memberId).set({email: member.email || '', status: member.status || ''});
    }else if(method === 'Delete'){
      singleMemberRef(member.id, member.memberId).remove();  // lid : Lot ID , bid : Bid ID
    }else{
      singleMemberRef(member.id, member.oldId).remove();
      singleMemberRef(member.id, member.memberId).set({email: member.email || '', status: member.status || ''});
    }

  }