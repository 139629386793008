import React,{ useState,useEffect,forwardRef  } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularLoading from "./CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import  languageJson  from "../config/language";
import { editBid } from '../actions/bidactions';
import MultipleSelect from './MutiSelection';
import { addUser, deleteAccountUser, editUserAccount } from '../actions/usersactions';
import {validateEmail} from '../utils/validation';
export default function UserDetails(rowData) {

    const truckType = useSelector(state => state.trucktypes.trucks);
    const auth = useSelector( state => state.auth.info.profile);

    let trucks = truckType.map(d =>   d.name); 
    const columns =  [
        { title: 'ID', field: 'uid',  editable: 'never'  },
        { title: languageJson.first_name, field: 'firstName' },
        { title: languageJson.last_name, field: 'lastName' },
        { title: languageJson.email,field: 'email'},
        { title: languageJson.user_type, field: 'usertype', lookup: { rider: 'Customer', driver: 'Driver', admin: 'Admin' } },
        { title: 'Role',field: 'roles', editComponent: props => (<MultipleSelect auth={auth} onChange={value => props.onChange(value)}/>)},
        { title:languageJson.user_created_date, field: 'createdAt', type: 'date', editable:'never'},

        ];

  const [data, setData] = useState([]);
  const accountsdata = useSelector(state => state.accountsdata);

  const dispatch = useDispatch();
  const unmounted = React.useRef(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


  useEffect(()=>{
    let users = [];
      console.log(rowData);
        if(rowData.users){
           for(let key in rowData.users){
             rowData.users[key].uid = key;
             users.push(rowData.users[key]);
           }
            setData(users);
        }
       
  },[rowData.users]);

  return (
    accountsdata.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.TRB || 'Users'}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={{
        exportButton: true,    
        filtering: true,    
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10,20,50]
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve,reject) => {
            setTimeout(() => {
              const tblData = data;
              tblData.push(newData);
              console.log('Aut...',newData)
              if(newData.email !== undefined ){
                if(!validateEmail(newData.email)){
                  reject();
                  return;
                }
              }else {
                  reject();
                  return;
              }
              resolve();
              newData.accountId = rowData.accountId;
              dispatch(addUser(newData));
            }, 600);
          }),

          onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
              console.log(validateEmail(newData.email))
              if(newData.email !== undefined ){
                if(!validateEmail(newData.email)){
                  reject();
                }
              }else {
                    reject();
              }
              resolve();
            newData.accountId = rowData.accountId;
            dispatch(editUserAccount(newData.uid, newData));
            }, 600);
          }),
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              newData.accountId = rowData.accountId;
              dispatch(deleteAccountUser(newData));
            }, 600);
          }),
      }}
    />
  );
}
