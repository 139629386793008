import React,{ useState,useEffect,forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import  languageJson  from "../config/language";
import BidderDetails from "../components/BidderDetails";
import { editLot } from '../actions/lotsactions';
import TextField from '@material-ui/core/TextField';

export default function Lots() {

  const truckType = useSelector(state => state.trucktypes.trucks) || [];
  let trucks = truckType.map(d =>    d.name );


    const columns =  [
        { title: 'Lot Id', field: 'displayId', editable: 'never',filtering: true },
        { title: 'Account',field: 'accountId',filtering: true },
        { title: languageJson.grain_type,field: 'grainType',filtering: true },
        { title: languageJson.truck_type, field: 'carType',filtering: true, lookup:  trucks.reduce((a, v) => ({ ...a, [v]: v}), {}) || []  },
        { title: languageJson.lot_start_location, field: 'startLocation' ,filtering: true },
        { title: 'From Lat', field: 'startLatitude',filtering: true  },
        { title: 'From Lng', field: 'startLongitude',filtering: true  },
        { title: languageJson.lot_end_location, field: 'endLocation',filtering: true  },
        { title: 'To Lat', field: 'endLatitude' ,filtering: true },
        { title: 'To Lng', field: 'endLongitude',filtering: true  },
        { title: languageJson.loads, field: 'loads', type:'numeric' ,filtering: true },
        { title: languageJson.tons, field: 'tons', type:'numeric',filtering: true  },
        { title: languageJson.price, field: 'price' , type: 'currency',filtering: true },  
        { title: languageJson.lot_start_date, field: 'startDate', type:'date' ,filtering: true },
        { title: languageJson.lot_end_date, field: 'endDate', type:'date' ,filtering: true },
        { title: 'Notes', field: 'Notes' },
        { title: languageJson.lot_status, field: 'status', lookup: { 'Private': 'Private', 'OnHold': 'On Hold', 'Open': 'Open', 'ToContract': 'To Contract', 'BidSelected': 'Bid Selected', 'Closed':'Closed', 'Cancelled': 'Cancelled','Withdrawn': "Withdrawn" }},
        { title: languageJson.lot_status_reason, field: 'statusReason',filtering: true  },
        { title: 'Shipping Guidance', field: 'guidance', type: 'currency' ,filtering: true },
        { title: 'Guidance and Truck Fees', field: 'guidanceTotal',  type: 'currency',filtering: true  },
        { title: 'Created', field: 'CreatedOn', type:'date',  editable:'never',filtering: true, defaultSort: 'desc' },
        { title: 'Last Modified', field: 'ModifiedOn', type:'date', editable:'never',filtering: true  },
        ];

  const [data, setData] = useState([]);
  const auth = useSelector( state => state.auth.info.profile);
  const lotsdata = useSelector(state => auth.isAdmin ? state.lotsdata.lots : state.lotsdata?.lots?.filter( d => d.accountId === auth.accountId ));
  const dispatch = useDispatch();
  
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  useEffect(()=>{

        if((data?.length === 0 && lotsdata.length > 0) || data.length < lotsdata.length ){
            setData(lotsdata);
        }   
  },[data, lotsdata]);

  console.log("LotsData",lotsdata);

  return (
    data===null && lotsdata.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.lot_text}
      columns={columns}
      data={data}
      icons={tableIcons}
      options={{
        filtering: true,
        exportButton: true,
        exportAllData: true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10,20,50]
      }}
      detailPanel={rowData => {
        return (
         <BidderDetails row={rowData} />
        )
      }}
      onRowClick={(event, rowData, togglePanel) => togglePanel()}
      editable={ auth.isAdmin &&{ 
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.push(newData);
              dispatch(editLot(newData, "Add"));
            }, 600);
          }),

          onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
              dispatch(editLot(newData, "Update"));
            }, 600);
          }),
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
             dispatch(editLot(newData,"Delete"));
            }, 600);
          }),
      }}
    />
  );
}
