import { userRef, singleUserRef, singleAccountRef, storageRef } from "../config/firebase";
import { 
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_SUCCESS,
    FETCH_ALL_USERS_FAILED,
    EDIT_USER,
    EDIT_USER_SUCCESS,
    EDIT_USER_FAILED,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    ADD_ACCOUNT,
    ADD_ACCOUNT_SUCCESS,
    ADD_ACCOUNT_FAILED,
    EDIT_ACCOUNT,
    EDIT_ACCOUNT_SUCCESS,
    EDIT_ACCOUNT_FAILED,
    FILE_UPLOADED,
    FILE_UPLOADED_SUCCESS,
    FILE_UPLOADED_FAILED,
} from "./types";

import {functions} from '../config/firebase';
import { uuid } from 'uuidv4';

export const fetchUsers = () => dispatch => {
    dispatch({
      type: FETCH_ALL_USERS,
      payload: null
    });
    userRef.on("value", snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i
          return data[i]
        });
        dispatch({
          type: FETCH_ALL_USERS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_ALL_USERS_FAILED,
          payload: "No users available."
        });
      }
    });
  };

  export const editUser = (id,user) => dispatch =>{
    dispatch({
      type: EDIT_USER,
      payload: user
    });
    let editedUser = user;
    if(user.referralBonus) editedUser.referralBonus = parseFloat(editedUser.referralBonus);
    delete editedUser.id;
    singleUserRef(id).set(editedUser).then(()=>{
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: null
      });  
    }).catch((error)=>{
      dispatch({
        type: EDIT_USER_FAILED,
        payload: error
      });        
    });
  }
 

  export const editUserAccount = (id,user) => dispatch =>{
    dispatch({
      type: EDIT_USER,
      payload: user
    });
    let editedUser = user;
    if(user.referralBonus) editedUser.referralBonus = parseFloat(editedUser.referralBonus);
    delete editedUser.id;
    singleUserRef(id).set(editedUser).then(()=>{
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: null
      });  
    }).catch((error)=>{
      dispatch({
        type: EDIT_USER_FAILED,
        payload: error
      });        
    });

    singleAccountRef(user.accountId).child('/users/' + id).set(user).then(()=>{
      dispatch({
        type: EDIT_ACCOUNT_SUCCESS,
        payload: null
      });  
    }).catch((error)=>{
      dispatch({
        type: EDIT_ACCOUNT_FAILED,
        payload: error
      });        
    });
  }
  export const addUser = (data) => dispatch => {
     dispatch({
       type:ADD_ACCOUNT,
       payload:null
     });
    let registerUser = functions.httpsCallable('registerUser');
    registerUser(data).then( res => {
      dispatch({
        type:ADD_ACCOUNT_SUCCESS,
        payload:res
      })
     }).catch(error => {
      var code = error.code;
      var message = error.message;
      var details = error.details;
      alert(message);
      dispatch({
        type:ADD_ACCOUNT_FAILED,
        payload:error
      })
      
     });
  }
 export const deleteAccountUser = (data) => dispatch => {
  dispatch({
    type: DELETE_USER,
    payload: null
  });
  let deleteUser = functions.httpsCallable('deleteUser');
  data.type = 'user';
  deleteUser(data).then(res => {
     console.log(res);
     dispatch({
      type: DELETE_USER_SUCCESS,
      payload: res
    });
  }).catch(error => {
      var code = error.code;
      var message = error.message;
      var details = error.details;
      alert(message);
      dispatch({
        type: DELETE_USER_FAILED,
        payload: error
      });
  });

 }
 
  export const deleteUser = (id) => dispatch =>{
    dispatch({
      type: DELETE_USER,
      payload: id
    });

    singleUserRef(id).remove().then(()=>{
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: null
      });  
    }).catch((error)=>{
      dispatch({
        type: DELETE_USER_FAILED,
        payload: error
      });        
    });

  }

  export const uploadFile = (file) => dispatch => {
   console.log('formData...',file);
   dispatch({
     type:FILE_UPLOADED,
   })
   const filePath = `driver_licenses/${uuid()}/`;

   storageRef.child(filePath).put(file).then((snapshot) => {
    const percentUploaded = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    
    if(percentUploaded == 100){
      snapshot.ref.getDownloadURL().then(downloadUrl => {
        console.log('this is the image url', downloadUrl);
        dispatch({
          type:FILE_UPLOADED_SUCCESS,
          payload: downloadUrl
        })
      })
     
    }
    console.log('Uploaded a blob or file!', percentUploaded);
  });

  }