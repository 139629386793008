import React,{ useState,useEffect,forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularLoading from "./CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import  languageJson  from "../config/language";
import { editBid } from '../actions/bidactions';
export default function Lots(rowData) {

    const truckType = useSelector(state => state.trucktypes.trucks);
    const auth = useSelector( state => state.auth.info.profile);
  
    let trucks = truckType.map(d => d.name );
    const columns =  [
        { title: 'Bid Id' , field: 'displayId', editable:'never'},
        { title: languageJson.bid_price,field: 'bidPrice', type:'currency'},
        { title: languageJson.bidder + ' Id' , field: 'bidder' },
        { title: 'Bidder Account' , field: 'accountId', editable:'never'},
        { title: languageJson.bid_rating, field: 'bidderRating' , filtering: true},
        { title: 'Full Contract Bid' , field: 'fullContractBid', type:'boolean'},
        { title: languageJson.bid_load_count, field: 'loads', type: 'numeric' },
        { title: languageJson.bid_truck_type, field: 'truckType', lookup:  trucks.reduce((a, v) => ({ ...a, [v]: v}), {})  },
        { title: 'Rate Class ', field: 'rateClass' , lookup: {"Primary Rate": "Primary Rate", "Secondary Rate": "Secondary Rate" }},
        { title: 'Created', field: 'CreatedOn' , type:'datetime' },
        // { title: languageJson.bid_start_date_time, field: 'bidDateTime' , type:'datetime' },
        // { title: languageJson.bid_start_date, field: 'startDate', type:'date' },
        // { title: languageJson.bid_end_date, field: 'endDate', type: 'date' },

        ];

  const [data, setData] = useState([]);
  const biddersdata = useSelector(state => state.lotsdata);

  const dispatch = useDispatch();
  const unmounted = React.useRef(false);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


  useEffect(()=>{
        let bidder = [];
        if(rowData.row.Bids){
            for(let key in rowData.row.Bids){
                rowData.row.Bids[key].bid = key;
                rowData.row.Bids[key].lid = rowData.row.id;
                bidder.push(rowData.row.Bids[key]);

            }
            if(!unmounted.current)
            setData(bidder);
        }
        return () => {
           unmounted.current = true;
        }
  },[rowData.row.Bids, rowData.row.id]);
  return (
    biddersdata.loading? <CircularLoading/>:
    <MaterialTable
      title={languageJson.bid_text}
      columns={columns}
      icons={tableIcons}
      data={data}
      options={{
        filtering: true,
        exportButton: true,
        searchFieldAlignment: "left",
        toolbarButtonAlignment: "left",
        pageSize: 5,
        pageSizeOptions: [5,10,20]

        
      }}
      editable=  { auth.isAdmin && {
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.push(newData);
              // dispatch(editBid(newData));
            //   dispatch(editNotifications(newData,"Add"));
            }, 600);
          }),

          onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
              dispatch(editBid(newData,"Update"));
            }, 600);
          }),
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
            //   dispatch(editNotifications(newData,"Delete"));
            }, 600);
          }),
      }}
    />
  );
}
