import { allUserRef , notifyAdminRef, notifyAdminEditRef } from "../config/firebase";
import {
  ADMIN_FETCH_NOTIFICATIONS,
  ADMIN_FETCH_NOTIFICATIONS_SUCCESS,
  ADMIN_FETCH_NOTIFICATIONS_FAILED,
  ADMIN_EDIT_NOTIFICATIONS,
  ADMIN_SEND_NOTIFICATION,
  ADMIN_SEND_NOTIFICATION_SUCCESS,
  ADMIN_SEND_NOTIFICATION_FAILED,
} from "./types";
import moment from 'moment';
import { store } from "../reducers/store";
//import firebase from 'firebase/app';
import 'firebase/database';
import  languageJson  from "../config/language";
export const fetchAdminNotifications = () => dispatch => {
  dispatch({
    type: ADMIN_FETCH_NOTIFICATIONS,
    payload: null
  });
  notifyAdminRef.on("value", snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
       
      const arr = Object.keys(data).map(i => {
        data[i].id = i
        return data[i]
      });

      dispatch({
        type: ADMIN_FETCH_NOTIFICATIONS_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: ADMIN_FETCH_NOTIFICATIONS_FAILED,
        payload: "No data available."
      });
    }
  });
};

export const editAdminNotifications = (notifications, method) => dispatch => {
  
  notifications.sentDateTime = moment().format('M-d-Y h:m:s');
 
  dispatch({
    type: ADMIN_EDIT_NOTIFICATIONS,
    payload: { method, notifications }
  });
  let contractData = store.getState().contractsdata.contracts;
  let accountData = store.getState().accountsdata.accounts;
  if(contractData.filter( d => d.id === notifications.contractId).length !== 0 ){
    notifications.members = contractData.filter( d => d.id === notifications.contractId)[0]['members'];
  }
  if(accountData.filter(d =>  d.id === notifications.accountId).length !== 0){
      let allUsers = {};
      let users = accountData.filter( d => d.id === notifications.accountId)[0]['users'];
      for(let key in users){
        if(users[key] === 'Admin')
         allUsers[key] = 'Admin';
      }
      notifications.adminUsers = allUsers; 
  }
  if(method === 'Add' ){
    notifyAdminRef.push(notifications);
  }else if(method === 'Delete'){
    notifyAdminEditRef(notifications.id).remove();
  }else{
    notifyAdminEditRef(notifications.id).set(notifications);
  }
}

export const sendAdminNotification = (notification) => dispatch => {
  dispatch({
    type: ADMIN_SEND_NOTIFICATION,
    payload: notification
  });

  let users = store.getState().usersdata.users;
  let arr = [];
  for (let i = 0; i < users.length; i++) {
    let usr = users[i];
    let obj = {
      to: null,
      title: notification.title,
      body: notification.body,
      "data": { "msg": notification.body, "title": notification.title },
      priority: "high",
      sound: "default",
      badge: '0'
    };
    if(notification.type === 'Global'){
      if (usr.pushToken) {
        obj.to = usr.pushToken;
        arr.push(obj);
      }
    } else if(notification.type === 'NonGlobal'){
      return false;
    } else if (notification.usertype === 'All' && notification.devicetype === 'All') {
      if (usr.pushToken) {
        obj.to = usr.pushToken;
        arr.push(obj);
      }
    } else if (notification.usertype === 'All' && notification.devicetype !== 'All') {
      if (usr.pushToken && usr.userPlatform === notification.devicetype) {
        obj.to = usr.pushToken;
        arr.push(obj);
      }
    } else if (notification.usertype !== 'All' && notification.devicetype === 'All') {
      if (usr.pushToken && usr.usertype === notification.usertype) {
        obj.to = usr.pushToken;
        arr.push(obj);
      }
    } else {
      if (usr.pushToken && usr.usertype === notification.usertype && usr.userPlatform === notification.devicetype) {
        obj.to = usr.pushToken;
        arr.push(obj);
      }
    }
  }

  if (arr.length > 0) {
    fetch('https://exp.host/--/api/v2/push/send', {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(arr),
    }).then((responseJson) => {
      dispatch({
        type: ADMIN_SEND_NOTIFICATION_SUCCESS,
        payload: responseJson
      });
      alert(arr.length + languageJson.notification_sent);
    })
      .catch((error) => {
        dispatch({
          type: ADMIN_SEND_NOTIFICATION_FAILED,
          payload: error,
        });
        alert(languageJson.notification_sent_failed);
      });
  } else {
    dispatch({
      type: ADMIN_SEND_NOTIFICATION_FAILED,
      payload: languageJson.no_user_match,
    });
    alert(languageJson.no_user_matching);
  }
}
